import { http } from '../utils/http'

export async function code2Token(params: { code: string; invite_code?: string }) {
  return http.post('/wechat/h5Login', params)
}

export async function fetchUseInfo() {
  return http.get('/user/me')
}

export async function fetchInviteList() {
  return http.get('/account/inviteList')
}

export async function fetchInviteTotal() {
  return http.get('/account/inviteTotal')
}

export async function fetchWechatConfig(url: string) {
  return http.get(`/wechat/share?url=${encodeURIComponent(url)}`)
}