import { useNavigate } from 'react-router-dom'
import './index.scss'
import { useEffect } from 'react'

import { cacheKeys } from '../../utils/constant'
import { get } from '../../utils/storage'
import { appSlice } from '../../store/app'
import { useDispatch } from 'react-redux'
import { fetchUseInfo } from '../../api/auth'
import { wechatLogin } from '../../utils/wechat'

export default function Home() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const token = get<string>(cacheKeys.token) || ''
    if (token) {
      fetchUseInfo().then(res => {
        dispatch(appSlice.actions.login(res.data))
        navigate('/invite')
      }).catch(() => {
        wechatLogin()  
      })
    } else {
      wechatLogin()
    }
  }, [dispatch, navigate])

  return <div></div>
}
