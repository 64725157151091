import { ExclamationCircleOutlined } from '@ant-design/icons'
import './index.scss'
import { SpinLoading } from 'antd-mobile'
import { useEffect, useState } from 'react'

import { code2Token, fetchUseInfo } from '../../api/auth'
import { searchQuery } from '../../utils/url'
import { set } from '../../utils/storage'
import { appSlice } from '../../store/app'
import { cacheKeys } from '../../utils/constant'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const [loading, setLoading] = useState<boolean>(false)
  const [msg, setMsg] = useState<string>()

  useEffect(() => {
    let token = searchQuery('tt')
    if (token) {
      // PC扫码
      set(cacheKeys.token, decodeURIComponent(token), 216000 * 1000)
      setTimeout(() => {
        fetchUseInfo()
          .then(res => {
            dispatch(appSlice.actions.login(res.data))
            window.location.href = '/invite'
          })
          .catch(err => {
            setMsg(err.msg)
          })
      }, 300)
      return
    }
    // 微信回调
    const code = searchQuery('code')
    const state = searchQuery('state')
    if (code) {
      code2Token({
        code,
        invite_code: state ? state : undefined
      })
        .then((res: any) => {
          console.log(res)
          set(cacheKeys.token, res.access_token, 10 * 60 * 1000)
          if (state) {
            navigate(`/receive?uid=${state}`)
          } else {
            navigate('/')
          }
        })
        .catch(err => {
          console.log(err)
          setMsg('出现错误，请退出重新进入')
        })
    } else {
      setTimeout(() => {
        setMsg('出现错误，请退出重新进入')
      }, 3000)
    }
  }, [dispatch, navigate])

  return (
    <div className="login flex-center flex-column">
      {msg ? <ExclamationCircleOutlined style={{ color: '#ff3141', fontSize: 38 }} /> : <SpinLoading />}
      <span className="mt-14">{msg ? msg : '正在登录中'}</span>
    </div>
  )
}
