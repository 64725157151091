import { isProduction, domain } from './constant'

export function wechatLogin(state?: string) {
  const appid = 'wx9b3d0b1d39924191'
  const href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
    `https://share.${domain}/login`
  )}&response_type=code&forcePopup=true&scope=snsapi_userinfo${state ? `&state=${state}` : ''}#wechat_redirect`
  if (isProduction) {
    window.location.href = href
  } else {
    console.log(href)
  }
}
