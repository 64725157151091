import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'

// import { cacheKeys } from '../utils/constant'
// import { get, set, remove } from '../utils/storage'

export interface IAppState {
  user?: any
}

export const appSlice = createSlice<IAppState, SliceCaseReducers<IAppState>>({
  name: 'app',
  initialState: {
  },
  reducers: {
    login: (state, action) => {
      // set(cacheKeys.user, action.payload, Number(action.payload.expires_in) * 1000)
      // set(cacheKeys.token, action.payload.access_token, Number(action.payload.expires_in) * 1000)
      state.user = action.payload
    },
    logout: state => {
      // remove(cacheKeys.user)
      // remove(cacheKeys.token)
      state.user = undefined
    }
  }
})

// function _getCache() {
//   const c = get(cacheKeys.user)
//   if (c) {
//     return c
//   }
//   remove(cacheKeys.user)
//   return undefined
// }
