import './index.scss'
import { Mask, Toast } from 'antd-mobile'
import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { searchQuery } from '../../utils/url'
import { get } from '../../utils/storage'
import { cacheKeys, pc } from '../../utils/constant'
import { useNavigate } from 'react-router-dom'
import { wechatLogin } from '../../utils/wechat'

export default function Receive() {
  const navigate = useNavigate()
  const [receiveDialog, setReceiveDialog] = useState<boolean>(false)
  const [successDialog, setSuccessDialog] = useState<boolean>(false)

  useEffect(() => {
    const token = get<string>(cacheKeys.token) || ''
    const _uid = searchQuery('uid')
    if (_uid && !token) {
      setReceiveDialog(true)
      return
    }
    if (_uid && token) {
      setSuccessDialog(true)
      return
    }
    navigate('/')
  }, [navigate])

  return (
    <div className="receive pt-20">
      <div className="body pt-20">
        <img src="images/logo.png" style={{ width: 100, display: 'block' }} className="ml-auto mr-auto" alt="闪模" />
        <div className="mt-25 pb-15 fs-13 color-fff">
          为千万商家提供AI模特，提供模特秒换装、定制场景、定制模特等服务
        </div>
        <img src="images/receive-steps.png" className="banner" alt="闪模" />
        <img src="images/receive-price.png" className="banner mt-25" alt="闪模" />
      </div>
      <div className="foot flex-center">
        <CopyToClipboard
          text={pc}
          onCopy={() => {
            Toast.show({
              content: `网址${pc}已复制，请使用电脑版浏览器打开`
            })
          }}
        >
          <div className="foot_btn flex-center cursor-pointer">立即使用</div>
        </CopyToClipboard>
      </div>
      <Mask visible={receiveDialog} opacity={0.9} onMaskClick={() => setReceiveDialog(false)}>
        <div className="receive-dialog" style={{ backgroundImage: 'url("images/red-pack-dialog.png")' }}>
          <p style={{ fontSize: 14, color: '#E33252', marginTop: 82 }}>获得现金红包</p>
          <img className="mt-20" style={{ width: 130 }} src="images/red-pack-num.png" alt="红包" />
          <p style={{ fontSize: 14, color: '#fff', marginTop: 40 }}>注册闪模即可领取</p>
          <div
            className="btn flex-center cursor-pointer"
            onClick={() => {
              const _uid = searchQuery('uid')
              wechatLogin(_uid || '')
            }}
          >
            立即领取
          </div>
          <div className="close cursor-pointer" onClick={() => setReceiveDialog(false)}>
            <img src="images/icon-dialog-close.png" alt="" />
          </div>
        </div>
      </Mask>
      <Mask visible={successDialog} opacity={0.9} onMaskClick={() => setSuccessDialog(false)}>
        <div
          className="receive-dialog receive-success"
          style={{ backgroundImage: 'url("images/receive-success.png")' }}
        >
          <div
            style={{
              color: '#994000',
              fontSize: 20,
              fontWeight: 600,
              marginTop: 120
            }}
          >
            领取成功
          </div>
          <div
            style={{
              color: '#994000',
              fontSize: 12,
              marginTop: 40
            }}
          >
            登录可使用优惠券，获得AI模特服务
          </div>
          <div
            className="flex-center cursor-pointer"
            style={{
              width: 240,
              height: 42,
              borderRadius: 40,
              border: '1px solid rgba(241, 184, 126, 0.50)',
              background: 'linear-gradient(180deg, #FFF386 0%, #FFDB5A 100%)',
              margin: '20px auto 0 auto',
              color: '#994000',
              fontSize: 18,
              fontWeight: 600
            }}
            onClick={() => setSuccessDialog(false)}
          >
            前往使用
          </div>
          <div className="close cursor-pointer" onClick={() => setSuccessDialog(false)}>
            <img src="images/icon-dialog-close.png" alt="" />
          </div>
        </div>
      </Mask>
    </div>
  )
}
