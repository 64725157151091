import './index.scss'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SpinLoading, Avatar, Popup, Button, Toast, Empty, Mask } from 'antd-mobile'
import { searchQuery } from '../../utils/url'
import { useNavigate } from 'react-router-dom'
import { QRCodeCanvas } from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { fetchInviteList, fetchInviteTotal, fetchWechatConfig } from '../../api/auth'
import { get } from '../../utils/storage'
import { cacheKeys, web } from '../../utils/constant'
import { isWeChat } from '../../utils/rem'

declare const wx: any

const oneRedPack = 10

export default function Invite() {
  const navigate = useNavigate()
  const user = useSelector<any, any>(state => state.app.user)
  const [loading] = useState<boolean>(false)
  const [inviteTatal, setInviteTotal] = useState<any>()
  const [inviteList, setInviteList] = useState<any[]>([])

  const [sharePopup, setSharePopup] = useState<boolean>(false)
  const [shareUrl, setShareUrl] = useState<string>()
  const [shareError, setShareError] = useState<boolean>(false)
  const [descPopup, setDescPopup] = useState<boolean>(false)

  const share = useCallback(() => {
    if (isWeChat() && !shareError) {
      Toast.show({
        content: '点击右上角转发'
      })
      return
    }
    setSharePopup(true)
  }, [shareError])

  useEffect(() => {
    let token = searchQuery('tt')
    if (token) {
      // PC扫码进的
      navigate(`/login?tt=${token}`, {
        replace: true
      })
      return
    }
    token = get<string>(cacheKeys.token) || ''
    if (token && !user) {
      navigate('/')
      return
    }
    if (isWeChat()) {
      fetchWechatConfig(`${web}/invite`)
        .then(res => {
          wx.ready(function () {
            const title = '送你10元红包，快来和我一起使用AI模特吧！'
            const link = `${web}/receive?uid=${user.invite_code}`
            const imgUrl = `${web}/images/logo-icon.png`
            wx.updateAppMessageShareData({
              title, // 分享标题
              desc: '随时随地用AI模特试穿你的服装，从此告别传统模特。', // 分享描述
              link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl, // 分享图标
              success: function () {
                // 设置成功
                console.log('updateAppMessageShareData success')
              }
            })
            wx.updateTimelineShareData({
              title, // 分享标题
              link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl, // 分享图标
              success: function () {
                // 设置成功
                console.log('updateTimelineShareData success')
              }
            })
            // wx.onMenuShareTimeline({
            //   title,
            //   link,
            //   imgUrl
            // })
            // wx.onMenuShareAppMessage({
            //   title,
            //   link,
            //   imgUrl,
            //   type: 'link'
            // })
            wx.hideMenuItems({
              menuList: [
                'menuItem:share:qq',
                'menuItem:share:weiboApp',
                'menuItem:favorite',
                'menuItem:share:facebook',
                'menuItem:share:QZone'
              ] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
            })
          })
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: [
              'updateAppMessageShareData',
              'updateTimelineShareData',
              'hideMenuItems'
              // 'onMenuShareTimeline',
              // 'onMenuShareAppMessage'
            ] // 必填，需要使用的JS接口列表
          })
        })
        .catch(() => {
          setShareError(true)
        })
    }
    setShareUrl(`${web}/receive?uid=${user.invite_code}`)
    fetchInviteList().then(res => {
      setInviteList(res.data)
    })
    fetchInviteTotal().then(res => {
      setInviteTotal(res.data)
    })
  }, [navigate, user])

  return (
    <div className="invite">
      {loading ? (
        <div className="pt-40 flex-center">
          <SpinLoading color="primary" />
        </div>
      ) : (
        <div className="pt-20">
          <img src="images/logo.png" style={{ width: 100, display: 'block' }} className="ml-auto mr-auto" alt="闪模" />
          <img
            src="images/title.png"
            style={{ width: 330, display: 'block' }}
            className="mt-26 ml-auto mr-auto"
            alt="标题"
          />
          <div className="desc flex mt-16 ml-auto mr-auto" style={{ backgroundImage: 'url(images/desc-bg.png)' }}>
            <span>每邀请 1 位新用户，领取</span>
            <span className="price">{oneRedPack}</span>
            <span className="unit">元</span>
            <span>现金奖励</span>
          </div>
          <div className="red-pack mt-12">
            <div className="red-pack_bg">
              <img src="images/red-pack.png" style={{ width: '100%' }} alt="" />
            </div>
            <div className="red-pack_body flex-column">
              <p className="fs-14 text-center pt-30" style={{ color: '#E33252' }}>
                您得{oneRedPack}元现金，您好友可得{oneRedPack}元大红包
              </p>
              <div className="mt-25 text-center">
                <img style={{ width: 130 }} src="images/red-pack-num.png" alt="红包" />
              </div>
              <div className="red-pack_notice text-center mt-auto">
                {/* <span>小小猪：已获得</span>
                <span className="price">{oneRedPack}元</span>
                <span>大红包</span> */}
              </div>
              <div className="red-pack_btn mt-11 ml-auto mr-auto cursor-pointer" onClick={share}>
                立即赚现金
              </div>
              <div
                style={{ height: 48 }}
                className="flex-center"
                onClick={() => {
                  setDescPopup(true)
                }}
              >
                <span className="fs-12 color-fff cursor-pointer">查看赚钱攻略</span>
                <img className="ml-2" src="images/icon-more.png" style={{ width: 10, height: 10 }} alt="" />
              </div>
              <div style={{ height: 10 }}></div>
            </div>
          </div>
          <div className="border-box mt-20 pl-25 pr-25">
            <img src="images/steps.png" style={{ width: '100%' }} alt="步骤" />
          </div>
          <div className="border-box mt-20 pl-25 pr-25">
            <div className="users">
              <div className="pt-20">
                <div className="box-title flex-center">
                  <div className="line"></div>
                  <div className="dot ml-5 mr-4"></div>
                  <div className="text ml-6 mr-6">奖励进度</div>
                  <div className="dot mr-4 mr-5"></div>
                  <div className="line line2"></div>
                </div>
                <div className="users_data flex-align-center flex-around">
                  <div className="flex-column flex-center">
                    <div className="price">
                      {inviteTatal ? parseInt(Number(inviteTatal.ongoing_amount / 100).toFixed(2)) : 0}
                    </div>
                    <div className="text">在途资金</div>
                  </div>
                  <div className="flex-column flex-center">
                    <div className="price">
                      {inviteTatal ? parseInt(Number(inviteTatal.had_amount / 100).toFixed(2)) : 0}
                    </div>
                    <div className="text">已获得</div>
                  </div>
                  <div className="flex-column flex-center">
                    <div className="price">
                      {inviteTatal ? parseInt(Number(inviteTatal.expire_amount / 100).toFixed(2)) : 0}
                    </div>
                    <div className="text">已失效</div>
                  </div>
                </div>
                <div className="border-box pt-5 pb-5 pl-15 pr-15">
                  <div className="users_list">
                    {inviteList.map((item, index) => {
                      return (
                        <div className="flex-align-center" key={index}>
                          <Avatar style={{ '--size': '42px', '--border-radius': '50%' }} src={item.avatar}></Avatar>
                          <div className="flex-column ml-10">
                            <div className="fs-14">{item.nickname}</div>
                            <div className="fs-12 mt-2">{item.expire_time}失效</div>
                          </div>
                          <div className="fs-14 ml-auto" style={{ color: '#6D39FF' }}>
                            VIP {item.status === 0 ? '待激活' : '已激活'}
                          </div>
                        </div>
                      )
                    })}
                    {inviteList.length <= 0 ? (
                      <div style={{ height: 120 }}>
                        <Empty description="暂无邀请用户" />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-30"></div>
          <Mask visible={descPopup} onMaskClick={() => setDescPopup(false)}>
            <div className="desc-popup">
              <div className="flex-column">
                <div className="title mt-20 text-center">赚钱攻略</div>
                <div className="text mt-20">1.被邀请的好友未注册闪模</div>
                <div className="text mt-10">2.被邀请的好友在邀请链接 有效期(30天)内成功开通VIP会员</div>
                <div className="text mt-10">3.邀请成功后可获得{oneRedPack}元奖励， 奖励可提现</div>
                <div className="btn cursor-pointer flex-center mt-auto" onClick={() => setDescPopup(false)}>
                  知道了
                </div>
                <div className="pb-25"></div>
              </div>
            </div>
          </Mask>
          <Popup
            visible={sharePopup}
            onMaskClick={() => setSharePopup(false)}
            onClose={() => setSharePopup(false)}
            bodyStyle={{ borderRadius: '14px 14px 0 0' }}
          >
            <h2 className="flex-center pt-14 pb-14">分享给好友</h2>
            <div className="flex-column flex-center pl-25 pr-25 pt-12">
              {shareUrl ? <QRCodeCanvas value={shareUrl} size={168} /> : ''}
              <div className="pt-14" style={{ color: '#999' }}>
                邀请二维码
              </div>
              <div className="mt-20" style={{ height: 1, backgroundColor: '#f1f1f1', width: '100%' }}></div>
            </div>
            <div className="fs-15 pt-20 pl-25 pr-25">分享链接：</div>
            <div className="flex-align-center pt-10 pl-25 pr-25 pb-28">
              <span className="fs-15 mr-20" style={{ width: '50vw' }}>
                {shareUrl}
              </span>
              <span className="ml-auto">
                <CopyToClipboard
                  text={`送你10元优惠券，快来和我一起用闪模。${shareUrl}`}
                  onCopy={() => {
                    Toast.show({
                      content: '已复制'
                    })
                  }}
                >
                  <Button size="small" color="primary">
                    复制链接
                  </Button>
                </CopyToClipboard>
              </span>
            </div>
          </Popup>
        </div>
      )}
    </div>
  )
}
