export const isProduction = !/localhost|simsir-lin|192/.test(window.location.href)
export const domain = 'shanmo.shop'
export const host = isProduction ? '/api' : `https://share.${domain}/api`
export const web = `https://share.${domain}`
export const pc = `https://${domain}`

export const cacheKeys = {
  token: 'MOBILE_TOKEN_CACHE',
  user: 'MOBILE_USER_CACHE'
}
