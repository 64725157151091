import * as ReactDOM from 'react-dom/client'
import './index.scss'
import { onresize, countRemPx } from './utils/rem'

import VConsole from 'vconsole'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'

import React from 'react'
import Home from './pages/home'
import Receive from './pages/receive'
import Invite from './pages/invite'
import Login from './pages/login'
import Error from './pages/error'
import Loading from './components/loading'

document.documentElement.style.fontSize = `${countRemPx()}px`
document.documentElement.style.width = `100%`
document.documentElement.style.height = `100%`
onresize(0, () => {
  document.documentElement.style.fontSize = `${countRemPx()}px`
})

if (/64cb1f190/.test(window.location.href)) {
  new VConsole()
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <div className="main">
    <BrowserRouter>
      <React.Suspense fallback={<Loading />}>
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/invite" element={<Invite />} />
            <Route path="/receive" element={<Receive />} />
            <Route path="/login" element={<Login />} />
            <Route path="/error" element={<Error />} />
            <Route path="*" element={<Navigate replace to="/error" />} />
          </Routes>
        </Provider>
      </React.Suspense>
    </BrowserRouter>
  </div>
)
